<template>
  <div class="morale">
    <div
      class="morale__container col-11 col-sm-10 m-auto"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-offset="300"
    >
      <div class="morale__content d-flex justify-content-center">
        <span v-if="this.$i18n.locale === 'jp'">”3T”</span>
        {{ title }}
        <span v-if="this.$i18n.locale === 'vn'"> 3T</span>
        <div v-if="this.$i18n.locale === 'en'" style="display: contents">
          (the<span> 3T</span>)
        </div>
      </div>
      <div class="row pb-10">
        <div class="col pt-0">
          <div class="container">
            <div class="row">
              <div class="col-12 px-0">
                <CardItems :data="dataMorale"></CardItems>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardItems from '../../../components/Tools/Card';
import { mapState } from 'vuex';

export default {
  name: 'Morale',
  data() {
    return {
      title: this.$t('about_us').morale.title,
      cards: this.$t('about_us').morale.cards,
      about_us_head: this.$t('about_us.about_us_head'),
      dataMorale: [
        { icon: '/images/icons/aboutus/change.svg' },
        { icon: '/images/icons/aboutus/try.svg' },
        {
          icon: '/images/icons/aboutus/first-penguin.svg',
        },
      ],
    };
  },
  mounted() {
    this.dataMorale = this.dataMorale.map((item, indx) => ({
      ...item,
      title: this.cards[indx].title,
      sub: this.cards[indx].sub,
    }));
  },
  components: {
    CardItems,
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
  watch: {
    lang(val) {
      this.$i18n.locale = val;
      this.title = this.$t('about_us').morale.title;
      this.cards = this.$t('about_us').morale.cards;
      this.about_us_head = this.$t('about_us.about_us_head');
      this.dataMorale = this.dataMorale.map((item, indx) => ({
        ...item,
        title: this.cards[indx].title,
        sub: this.cards[indx].sub,
      }));
    },
  },
};
</script>

<style lang="scss" scroped>
.morale {
  width: 100%;
  padding: 50px 0;
  background: url('/images/bg/bg-3t.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .morale__container {
    margin: 0 auto;
  }
  .morale__content {
    position: relative;
    font-weight: 700;
    font-size: 40px;
    color: var(--blue-text);
    text-align: center;
    z-index: 0;
    margin-bottom: 36px;
    &::after {
      content: '';
      position: absolute;
      background-color: var(--orange-background);
      border-radius: 50%;
      width: 38px;
      height: 38px;
      z-index: -1;
      top: 0;
      left: -18px;
    }
    span {
      color: var(--orange);
      display: contents;
    }
  }
  @media (max-width: 768px) {
    .morale__content {
      font-size: 32px;
    }
  }
}
</style>
