<template>
  <div class="container">
    <div class="grid-container">
      <div v-for="(item, index) in cards" :key="index" class="p-2">
        <div class="flip-card">
          <div
            class="flip-card-inner"
            v-bind:id="`flip-card-${index}`"
            @click="toggle(index)"
          >
            <div class="flip-card-front">
              <div class="main-image">
                <img :src="item" class="image" alt="card" />
                <span>{{ data[index].title }}</span>
              </div>
            </div>
            <div
              class="flip-card-back"
              :style="{ background: backgrounds[index].color }"
            >
              <div class="main-image">
                <div class="content">
                  <p>{{ data[index].sub }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import card_01 from '../../../public/images/aboutus/card_01.png';
import card_02 from '../../../public/images/aboutus/card_02.png';
import card_03 from '../../../public/images/aboutus/card_03.png';
export default {
  name: 'card',
  data() {
    return {
      cards: [card_01, card_02, card_03],
      backgrounds: [
        { color: '#0BB9F4' },
        { color: '#1FCFA6' },
        { color: 'orange' },
      ],
    };
  },
  methods: {
    toggle(index) {
      document
        .getElementById(`flip-card-${index}`)
        .classList.toggle('rotate-180');
      console.log(document.getElementById(`flip-card-${index}`).classList);
    },
  },
  props: {
    data: Array,
  },
};
</script>

<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
  }
}
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 300px;
  perspective: 1000px;
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 1.5s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    cursor: pointer;
    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      border-radius: 10px;
    }

    .flip-card-front {
      background-color: white;
      display: flex;
      .main-image {
        margin: auto;
        display: grid;
        img {
          width: 110px;
          margin: auto;
        }
        span {
          font-size: 18px;
          margin-top: 20px;
          font-weight: bold;
          color: var(--blue-text);
        }
      }
    }

    .flip-card-back {
      padding: 10px;
      color: white;
      transform: rotateY(180deg);
      .main-image {
        display: flex;
        height: 100%;
        width: 100%;
        .content {
          padding: 0 10px;
          margin: auto;
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 1280px) {
  }
  @media (max-width: 1024px) {
    .flip-card-back .main-image p {
      font-size: 16px;
    }
    .flip-card-inner.rotate-180 {
      transform: rotateY(180deg);
    }
  }
  @media (min-width: 1025px) {
    &:hover .flip-card-inner {
      transform: rotateY(180deg);
    }
  }
}
</style>
